/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `약관`,
    TERMS: `사용자 계약`,
    PRIVACY: `개인 정보 정책`,
    COOKIE: `쿠키 정책`,
    AML: `자금세탁 방지 정책`,
    CONDUCT_CODE: `행동강령`,
    CUSTOMER_SERVICE: `고객 서비스`,
    HELP_CENTER: `지원 센터`,
    FEEDBACK: `피드백`,
    CONTACTS: `문의하기`,
    CHARGES: `요금`,
    SUPPORT: `서비스 지원`,
    ABOUT_US: `회사 소개`,
    ANNOUNCEMENTS: `공식 성명`,
    FAQ: `자주하는 질문`,
    DEPOSIT_WITHDRAW_STATUS: `입출금 현황`,
    OFFICIAL_VERIFICATION: `공식 검증`,
    LANGUAGES: `언어`,
    SERVICE_EMAIL: `서비스 이메일`,
    APP_DOWNLOAD: `앱 다운로드`
};
