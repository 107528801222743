import React, { memo } from 'react';
import { Spin } from 'antd';

import './style.scss';

const className = 'componentLoadingPlaceholder';

const LoadingPlaceholder = () => {
  return (
    <div className={`${className}`}>
      <Spin />
    </div>
  );
};

export default memo(LoadingPlaceholder);
