/**
 * 首页接口
 */
export default SERVER => ({

  /**
   * 首页行情
   * method GET
   */
  MARKET_TICKERS: `${SERVER}/fapi/tickers`,

  /**
   * K线图数据
   * method GET
   */
  MARKET_KLINES: `${SERVER}/fapi/klines`,

  /**
   * 特殊行情
   * method GET
   */
  MARKET_SPECIAL_TICKERS: `${SERVER}/api/user/tickers`,
  
});
