/**
 * 首页接口
 */
export default SERVER => ({

  /**
   * Banner
   * method GET
   */
  COPY_TRADE_BANNER_LIST: `${SERVER}/api/follow/banners`,

  /**
   * 带单交易员列表
   * method GET
   */
  LEAD_TRADER_LIST: `${SERVER}/api/follow/leaders`,

  /**
   * 带单交易员详情
   * method GET
   * [params]
   * leader_uid
   */
  LEAD_TRADER_DETAIL: `${SERVER}/api/follow/leader-info`,

  /**
   * 交易员历史
   * method GET
   * [params]
   * leader_uid
   * page
   */
  COPY_TRADER_HISTORY: `${SERVER}/api/follow/leader-history`,

  /**
   * 我的跟单信息
   * method GET
   */
  MY_COPY_INFO: `${SERVER}/api/follow/mycopy`,

  /**
   * 加入跟单
   * method POST
   * [params]
   * leader_uid
   */
  COPY_TRADE_JOIN: `${SERVER}/api/follow/join`,

  /**
   * 取消跟单
   * method POST
   * [params]
   * leader_uid
   */
  COPY_TRADE_CANCEL: `${SERVER}/api/follow/leave`,

  /**
   * 跟单用户列表
   * method GET
   * [params]
   * leader_uid
   * page
   */
  COPY_TRADE_FOLLOW_LIST: `${SERVER}/api/follow/users`,

});
