import { LOCAL_STORAGE, TRADE_TYPE_SPOT } from '../constants';

// 系统临时数据

/**
 * 系统配置
 */
export function setConfig(data) {
  window.localStorage[LOCAL_STORAGE.CONFIG] = JSON.stringify(data || {});
}

/**
 * 系统配置
 */
export function getConfig() {
  if (window.localStorage[LOCAL_STORAGE.CONFIG]) {
    return JSON.parse(window.localStorage[LOCAL_STORAGE.CONFIG]);
  }
  
  return null;
}

/**
 * 当前选中的币种
 */
export function getSymbol() {
  return window.localStorage[LOCAL_STORAGE.SYMBOL] || 'BTC';
}

/**
 * 当前选中的币种
 */
 export function setSymbol(symbol) {
  window.localStorage[LOCAL_STORAGE.SYMBOL] = symbol || '';
}

/**
 * 当前选中的交易类型
 */
export function getTradeType() {
  return window.localStorage[LOCAL_STORAGE.TRADE_TYPE] || TRADE_TYPE_SPOT;
}

/**
 * 当前选中的交易类型
 */
 export function setTradeType(tradeType) {
  window.localStorage[LOCAL_STORAGE.TRADE_TYPE] = tradeType || '';
}

/**
 * getLeadTrader
 */
export function getLeadTrader() {
  if (window.localStorage[LOCAL_STORAGE.LEAD_TRADER]) {
    return JSON.parse(window.localStorage[LOCAL_STORAGE.LEAD_TRADER]);
  }

  return {};
}

/**
 * setLeadTrader
 * @param {*} value
 */
export function setLeadTrader(value) {
  window.localStorage[LOCAL_STORAGE.LEAD_TRADER] = JSON.stringify(value || {});
}

/* ----------------- 模拟账户 ----------------- */

/**
 * 是否模拟账户
 */
export function isDemoAccount() {
  return (getDemoAccount() == '1');
}

/**
 * 真实账户:'0' / 模拟账户:'1'
 */
export function getDemoAccount() {
  return window.localStorage[LOCAL_STORAGE.DEMO_ACCOUNT] || '0';
}

/**
 * 真实账户:'0' / 模拟账户:'1'
 */
 export function setDemoAccount(value) {
  window.localStorage[LOCAL_STORAGE.DEMO_ACCOUNT] = value;
}
