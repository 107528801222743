import keyMirror from 'keymirror';
import { CALL_API } from '../../middleware/fetch';
import API from '../../middleware/apis';

export const ACTION_TYPES = keyMirror({
  USER_DETAIL: null,
  CHANGE_PWD: null,
  USER_KYC: null,
  SET_TRADE_PWD: null,
  CHANGE_TRADE_PWD: null,
  CHECK_TRADE_PWD: null,
  GET_USER_KYC: null,
  WITHDRAW_CONFIG: null, // 提币配置
  AUTH_SEND_CODE: null, // 发送验证码
  // 是否显示header
  COMMON_SET_SHOW_HEADER: null,
  // 是否显示footer
  COMMON_SET_SHOW_FOOTER: null,
  // 设置当前 显示 币种信息 
  SET_CURRENT_COIN_DATA: null,
});

// 系统配置
export const requestWithdrawConfig = success => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.WITHDRAW_CONFIG,
      url: API.WITHDRAW_CONFIG,
      method: 'GET',
      success,
    }
  };
}

// 请求用户详情
export const requestUserDetail = success => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.USER_DETAIL,
      url: API.USER_DETAIL,
      method: 'GET',
      success,
    }
  };
};

// 修改密码
export const requestChangePwd = (old_password, new_password, success, fail) => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.CHANGE_PWD,
      url: API.CHANGE_PWD,
      method: 'POST',
      data: {
        old_password,
        new_password,
      },
      success,
      fail,
    }
  };
};

// 请求用户实名认证信息
export const requestUserKyc = success => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.USER_KYC,
      url: API.USER_KYC,
      method: 'GET',
      success,
    }
  };
};

// 设置交易密码
export const requestSetTradePwd = (password, success) => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.SET_TRADE_PWD,
      url: `${API.SET_TRADE_PWD}`,
      method: 'POST',
      data: {
        password,
      },
      success,
    }
  };
};

// 修改交易密码
export const requestChangeTradePwd = (old_password, new_password, success, fail) => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.CHANGE_TRADE_PWD,
      url: `${API.CHANGE_TRADE_PWD}`,
      method: 'POST',
      data: {
        old_password,
        new_password,
      },
      success,
      fail,
    }
  };
};

// 校验交易密码
export const requestCheckTradePwd = (password, success, fail) => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.CHECK_TRADE_PWD,
      url: API.CHECK_TRADE_PWD,
      method: 'POST',
      data: {
        password,
      },
      success,
      fail,
    }
  };
};

// 用户kyc状态
export const requestGetUserKyc = () => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.GET_USER_KYC,
      url: `${API.USER_KYC}`,
      method: 'GET'
    }
  };
};

/**
 * 是否显示footer
 */
export const setShowHeader = isShowHeader => {
  return {
    type: ACTION_TYPES.COMMON_SET_SHOW_HEADER,
    data: { isShowHeader }
  };
};

/**
 * 是否显示footer
 */
export const setShowFooter = isShowFooter => {
  return {
    type: ACTION_TYPES.COMMON_SET_SHOW_FOOTER,
    data: { isShowFooter }
  };
};

/**
 * 发送验证码
 * @param {*} email
 * @param {*} type 1.注册 2.忘记密码
 * @param {*} success
 * @param {*} fail
 */
 export const requestSendVerificationCode = (email, type, success, fail) => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.AUTH_SEND_CODE,
      url: API.AUTH_SEND_CODE,
      method: 'POST',
      data: { email, type },
      success,
      fail,
    }
  };
};

/**
 * 设置当前 显示 币种信息
 */
 export const setCurrentCoinData = data => {
  return {
    type: ACTION_TYPES.SET_CURRENT_COIN_DATA,
    data: { ...data }
  };
};
