/**
 * index.js for translation
 **/
import setLanguage from 'utils/language';
import en from './en';
import ja from './ja';
import ko from './ko';
import fr from './fr';
import ar from './ar';
import es from './es';
import pt from './pt';
import zh from './zh';

const languages = [
    { code: 'en', language: en, name: 'English' },
    { code: 'ja', language: ja, name: 'Japanese' },
    { code: 'ko', language: ko, name: 'Korean' },
    { code: 'fr', language: fr, name: 'French' },
    { code: 'es', language: es, name: 'Spain' },
    { code: 'pt', language: pt, name: 'Portugal' },
    { code: 'ar', language: ar, name: 'Arabic' },
    { code: 'zh', language: zh, name: '简体中文' },
];
let language = null;

export const getLanguage = () => language;

export default (code = 'default') => {
    language = setLanguage(code, languages);
    return language.language;
};
