import { message } from 'antd';

export function showSuccessMsg(text) {
  message.success(text);
}

export function showErrorMsg(text) {
  message.error(text);
}

export function showWarningMsg(text) {
  message.warning(text);
}

