/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Declaração de termos`,
    TERMS: `Termo de Acordo do Usuário`,
    PRIVACY: `política de Privacidade`,
    COOKIE: `Política de Cookies`,
    AML: `Política contra lavagem de dinheiro`,
    CONDUCT_CODE: `Código de Conduta`,
    CUSTOMER_SERVICE: `Atendimento ao Cliente`,
    HELP_CENTER: `Centro de ajuda`,
    FEEDBACK: `Opinião`,
    CONTACTS: `Contate-nos`,
    CHARGES: `Cobranças`,
    SUPPORT: `serviço de suporte`,
    ABOUT_US: `sobre nós`,
    ANNOUNCEMENTS: `declaração oficial`,
    FAQ: `Perguntas frequentes`,
    DEPOSIT_WITHDRAW_STATUS: `Status de depósito e retirada`,
    OFFICIAL_VERIFICATION: `Verificação oficial`,
    LANGUAGES: `linguagem`,
    SERVICE_EMAIL: `E-mail de serviço`,
    APP_DOWNLOAD: `Baixar APLICATIVO`
};
