/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `条例説明`,
    TERMS: `ユーザー条例`,
    PRIVACY: `プライバシポリシー`,
    COOKIE: `Cookieポリシー`,
    AML: `マネーロンダリング防止ポリシー`,
    CONDUCT_CODE: `行動規範`,
    CUSTOMER_SERVICE: `サポートサービス`,
    HELP_CENTER: `ヘルプセンター`,
    FEEDBACK: `フィードバック`,
    CONTACTS: `コンタクト`,
    CHARGES: `利用料金`,
    SUPPORT: `サービスサポート`,
    ABOUT_US: `私たちについて`,
    ANNOUNCEMENTS: `公式声明`,
    FAQ: `よくある質問`,
    DEPOSIT_WITHDRAW_STATUS: `入出金状況`,
    OFFICIAL_VERIFICATION: `正式な検証`,
    LANGUAGES: `言語`,
    SERVICE_EMAIL: `サービスメール`,
    APP_DOWNLOAD: `APPダウンロード`
};
