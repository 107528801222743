/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `条款声明`,
    TERMS: `用户协议`,
    PRIVACY: `隐私政策`,
    COOKIE: `Cookie政策`,
    AML: `反洗钱政策`,
    CONDUCT_CODE: `行为准则`,
    CUSTOMER_SERVICE: `客服服务`,
    HELP_CENTER: `帮助中心`,
    FEEDBACK: `意见反馈`,
    CONTACTS: `联系我们`,
    CHARGES: `收费标准`,
    SUPPORT: `服务支持`,
    ABOUT_US: `关于我们`,
    ANNOUNCEMENTS: `官方声明`,
    FAQ: `FAQ`,
    DEPOSIT_WITHDRAW_STATUS: `充提币状态`,
    OFFICIAL_VERIFICATION: `官方验证`,
    LANGUAGES: `语言`,
    SERVICE_EMAIL: `服务邮箱`,
    APP_DOWNLOAD: `APP下载`
};
