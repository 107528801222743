import { LOCAL_STORAGE, LANGUAGE_DATAS } from '../constants';

/**
 * 语言包
 * @param code 浏览器语言code (default为浏览器语言)
 * @param languages 语言包配置形如: [{code: 'en', languages: en}, {code: 'zh-cn', languages: zh}]
 * @return 对应语言包的language
 */
export default (code, languages) => {
  if (code === 'default') {
    code = getLanguageCode();
  }
  let language = languages.filter(v => v.code === code);
  if (language.length === 0) {
    language = languages[0];
  } else {
    language = language[0];
  }
  return language;
};

/**
 * 浏览器语言
 */
export function languageCode() {
  const code = (navigator.browserLanguage || navigator.language).toLowerCase().split('-')[0];
  // const fullCode = (navigator.browserLanguage || navigator.language).toLowerCase();

  for (const data of LANGUAGE_DATAS) {
    if (code == data.code) {
      return code;
    }
  }

  return 'en';
}


/**
 * 当前浏览-保存语言
 */
export function setLanguageCode(value) {
  window.localStorage[LOCAL_STORAGE.LANGUAGE_CODE] = value || '';
}

/**
 * 当前浏览器-保存语言
 */
export function getLanguageCode() {
  return window.localStorage[LOCAL_STORAGE.LANGUAGE_CODE] || languageCode();
}

/**
 * 当前浏览器-保存语言 对应名称
 */
export function getLanguageName() {
  const languageCode = getLanguageCode();
  for (const data of LANGUAGE_DATAS) {
    if (data.code === languageCode) {
      return data.name;
    }
  }
  return '';
}

/**
 * 阿拉伯语 界面镜像布局
 * @returns 
 */
export function reverseLayout() {
  return (getLanguageCode() == 'ar');
}
