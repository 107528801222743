/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `página delantera`,
    MENU_MARKETS: `mercado`,
    MENU_ASSET: `activos`,
    MENU_PLAN: `Atender`,
    MENU_USER_CENTER: `Centro personal`,
    LOGOUT: `abandonar`,
    LOGIN: `Acceso`
};
