/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `첫 장`,
    MENU_MARKETS: `시장`,
    MENU_ASSET: `자산`,
    MENU_PLAN: `제공하다`,
    MENU_USER_CENTER: `개인 센터`,
    LOGOUT: `그만두다`,
    LOGIN: `로그인`
};
