/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Déclaration des conditions`,
    TERMS: `Accord de l'utilisateur`,
    PRIVACY: `politique de confidentialité`,
    COOKIE: `Politique en matière de cookies`,
    AML: `Politique anti-blanchiment d'argent`,
    CONDUCT_CODE: `code de conduite`,
    CUSTOMER_SERVICE: `Service client`,
    HELP_CENTER: `Centre d'aide`,
    FEEDBACK: `Retour`,
    CONTACTS: `Contactez-nous`,
    CHARGES: `Des charges`,
    SUPPORT: `service après vente`,
    ABOUT_US: `à propos de nous`,
    ANNOUNCEMENTS: `déclaration officielle`,
    FAQ: `FAQ`,
    DEPOSIT_WITHDRAW_STATUS: `Statut de dépôt et de retrait`,
    OFFICIAL_VERIFICATION: `Vérification officielle`,
    LANGUAGES: `langue`,
    SERVICE_EMAIL: `E-mail de service`,
    APP_DOWNLOAD: `Téléchargement de l'application`
};
