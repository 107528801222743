export default {
  INDEX_TOP: require('./index-top.jpg'),
  INDEX_FEATURE_1: require('./index-feature-1.svg'),
  INDEX_FEATURE_2: require('./index-feature-2.svg'),
  INDEX_FEATURE_3: require('./index-feature-3.svg'),
  INDEX_MAP_BG: require('./index-map-bg.png'),
  INDEX_NUMBER_ARROW_UP: require('./index-number-arrow-up.svg'),

  IMAGE_UPLOAD: require('./image-upload.png'),
  KYC_FAIL: require('./kyc-fail.png'),
  KYC_REVIEWING: require('./kyc-reviewing.png'),
  KYC_SUCCESS: require('./kyc-success.png'),
  NO_DATA: require('./no-data.png'),
  QT_PROFIT: require('./qt-profit.png'),

  ARROW: require('./arrow.svg'),

  OFFICE_SAN_FRANCISCO: require('./office-San-Francisco.png'),
  OFFICE_SYDNEY: require('./office-Sydney.png'),

  OUR_MISSION_1: require('./our-mission-1.png'),
  OUR_MISSION_2: require('./our-mission-2.png'),
  OUR_MISSION_3: require('./our-mission-3.png'),

  OUR_TECH_1: require('./our-tech-1.png'),
  OUR_TECH_2: require('./our-tech-2.png'),
  OUR_TECH_3: require('./our-tech-3.png'),
  OUR_TECH_4: require('./our-tech-4.png'),
  OUR_TECH_5: require('./our-tech-5.png'),
  OUR_TECH_6: require('./our-tech-6.png'),

  OUR_TEAM: require('./our-team.png'),

  M_MAIN_BANNER_BG: require('./m-main-banner-bg.svg'),
  M_MAIN_BANNER_COIN: require('./m-main-banner-coin.svg'),

  SHARE_IMAGE: require('./share-image.png'),
};
