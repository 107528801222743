/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Terms of Service`,
    TERMS: `User Agreement`,
    PRIVACY: `Privacy Policy`,
    COOKIE: `Cookie Policy`,
    AML: `AML Policy`,
    CONDUCT_CODE: `Code of Conduct`,
    CUSTOMER_SERVICE: `Customer Service`,
    HELP_CENTER: `Help Center`,
    FEEDBACK: `Feedback`,
    CONTACTS: `Contact Us`,
    CHARGES: `Charges`,
    SUPPORT: `Support`,
    ABOUT_US: `About Us`,
    ANNOUNCEMENTS: `Announcements`,
    FAQ: `FAQ`,
    DEPOSIT_WITHDRAW_STATUS: `Deposit & Withdraw status`,
    OFFICIAL_VERIFICATION: `Official Verification`,
    LANGUAGES: `Languages`,
    SERVICE_EMAIL: `Service Email`,
    APP_DOWNLOAD: `APP download`
};
