/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `الصفحة الأمامية`,
    MENU_MARKETS: `سوق`,
    MENU_ASSET: `أصول`,
    MENU_PLAN: `يخدم`,
    MENU_USER_CENTER: `مركز شخصي`,
    LOGOUT: `يترك`,
    LOGIN: `تسجيل الدخول`
};
