/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `page de garde`,
    MENU_MARKETS: `marché`,
    MENU_ASSET: `actifs`,
    MENU_PLAN: `Servir`,
    MENU_USER_CENTER: `Centre personnel`,
    LOGOUT: `quitter`,
    LOGIN: `Se connecter`
};
