/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `بيان الشروط`,
    TERMS: `اتفاقية المستخدم`,
    PRIVACY: `سياسة الخصوصية`,
    COOKIE: `سياسة ملفات الارتباط`,
    AML: `سياسة مكافحة غسيل الأموال`,
    CONDUCT_CODE: `مدونة لقواعد السلوك`,
    CUSTOMER_SERVICE: `خدمة الزبائن`,
    HELP_CENTER: `مركز المساعدة`,
    FEEDBACK: `تعليق`,
    CONTACTS: `اتصل بنا`,
    CHARGES: `رسوم`,
    SUPPORT: `دعم الخدمة`,
    ABOUT_US: `معلومات عنا`,
    ANNOUNCEMENTS: `الخطاب الرسمي`,
    FAQ: `التعليمات`,
    DEPOSIT_WITHDRAW_STATUS: `حالة الإيداع والسحب`,
    OFFICIAL_VERIFICATION: `التحقق الرسمي`,
    LANGUAGES: `لغة`,
    SERVICE_EMAIL: `البريد الإلكتروني للخدمة`,
    APP_DOWNLOAD: `تحميل التطبيق`
};
