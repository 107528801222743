import { ACTION_TYPES } from './action';

export const initialState = {
  btcUsd: 0,
  
  kyc: {
    exsit: false,
    info: {},
  },

  withdrawConfig: {
    btc: {
      min: 0.001,
      fee: 0.0001
    },
    eth: {
        min: 0.01,
        fee: 0.001
    },
    usdt: {
        min: 10,
        fee: 2
    }
  }, // 提币配置
  
  isShowHeader: true,
  isShowFooter: true,
  isShowMenu: false,

  currentCoinData: {
    symbol: "",
    subname: "",
    change: 0,
    close: 0,
    high: 0,
    low: 0,
    open: 0,
    quote: 0,
    volume: 0,
  },
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.GET_USER_KYC]: (state, action) => {
    const newState = { ...state };
    newState.requesting = false;
    newState.kyc.exsit = action.data.exsit;
    newState.kyc.info = action.data.info || {};
    return newState;
  },
  [`REQUESTING_${ACTION_TYPES.GET_USER_KYC}`]: state => {
    const newState = { ...state };
    newState.requesting = true;
    return newState;
  },
  [`FAIL_${ACTION_TYPES.GET_USER_KYC}`]: state => {
    const newState = { ...state };
    newState.requesting = false;
    return newState;
  },

  [ACTION_TYPES.WITHDRAW_CONFIG]: (state, action) => {
    const newState = { ...state };
    newState.requesting = false;
    newState.withdrawConfig = action.data || {};
    return newState;
  },
  [`REQUESTING_${ACTION_TYPES.WITHDRAW_CONFIG}`]: state => {
    const newState = { ...state };
    newState.requesting = true;
    return newState;
  },
  [`FAIL_${ACTION_TYPES.WITHDRAW_CONFIG}`]: state => {
    const newState = { ...state };
    newState.requesting = false;
    return newState;
  },

  // 设置是否显示haeder
  [ACTION_TYPES.COMMON_SET_SHOW_HEADER]: (state, action) => {
    const newState = { ...state };
    newState.isShowHeader = action.data.isShowHeader;
    return newState;
  },
  // 设置是否显示footer
  [ACTION_TYPES.COMMON_SET_SHOW_FOOTER]: (state, action) => {
    const newState = { ...state };
    newState.isShowFooter = action.data.isShowFooter;
    return newState;
  },

  // 设置
  [ACTION_TYPES.SET_CURRENT_COIN_DATA]: (state, action) => {
    const newState = { ...state };
    newState.currentCoinData = action.data;
    return newState;
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
