/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `primeira página`,
    MENU_MARKETS: `mercado`,
    MENU_ASSET: `ativos`,
    MENU_PLAN: `Servir`,
    MENU_USER_CENTER: `Centro Pessoal`,
    LOGOUT: `desistir`,
    LOGIN: `Conecte-se`
};
