/**
 * 登录注册接口
 */
export default SERVER => ({
  /**
   * 发送验证码
   * method POST
   */
  AUTH_SEND_CODE: `${SERVER}/api/auth/send_code`,

  /**
   * 注册
   * method POST
   */
  AUTH_SIGN_UP: `${SERVER}/api/auth/register_by_email`,

  /**
   * 登录
   * method POST
   */
  AUTH_SIGN_IN: `${SERVER}/api/auth/login`,

  /**
   * 忘记密码
   * method POST
   */
  AUTH_FORGET_PWD: `${SERVER}/api/auth/forget`,
  
  /**
   * 修改密码
   * method POST
   */
  AUTH_CHANGE_PWD: `${SERVER}/api/user/password`,

});
