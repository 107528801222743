import React from 'react';
import { Modal } from 'antd';

import { ICONS } from '../../assets'

import { getIosDownloadUrl } from '../../utils/url'

import L from './languages';

import './style.scss';

const className = 'componentDownloadApple';

class DownloadApple extends React.PureComponent {
  static propTypes = {
  }

  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.L = L();
  }

  onDownloadClick = () => {
    const url = getIosDownloadUrl();
    if (url) {
      window.location.href = url;
      return;
    }

    Modal.info({
      title: this.L.MODAL_TITLE,
      content: (
        <div>
          <p>{this.L.MODAL_CONTENT}</p>
        </div>
      ),
      okText: this.L.MODAL_BUTTON,
      onOk() {
      },
    });
  }

  render() {
    return (
      <div
        className={`${className}-btn`}
        onClick={() => this.onDownloadClick()}
      >
        <div className={`${className}-icon-box`}>
          <img className={`${className}-icon`} src={ICONS.APPLE} />
        </div>
        <div className={`${className}-apple`} />
      </div>
    );
  }
}

export default DownloadApple;
