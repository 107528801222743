/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Declaración de términos`,
    TERMS: `Acuerdo del Usuario`,
    PRIVACY: `política de privacidad`,
    COOKIE: `Política de cookies`,
    AML: `Política contra el lavado de dinero`,
    CONDUCT_CODE: `Código de conducta`,
    CUSTOMER_SERVICE: `Servicio al Cliente`,
    HELP_CENTER: `Centro de ayuda`,
    FEEDBACK: `Comentario`,
    CONTACTS: `Contáctenos`,
    CHARGES: `Cargos`,
    SUPPORT: `servicio de ayuda`,
    ABOUT_US: `sobre nosotros`,
    ANNOUNCEMENTS: `Declaración oficial`,
    FAQ: `Preguntas más frecuentes`,
    DEPOSIT_WITHDRAW_STATUS: `Estado de depósito y retiro`,
    OFFICIAL_VERIFICATION: `Verificación oficial`,
    LANGUAGES: `idioma`,
    SERVICE_EMAIL: `Correo electrónico de servicio`,
    APP_DOWNLOAD: `descarga de la aplicación`
};
